const TooltipHelper = {
    getPlacement(context, element) {
        // Get the position of the item that needs the tooltip
        const elementRect = element.getBoundingClientRect();

        // Show tool tip on either top or bottom depending on which has more viewport room
        if (elementRect.top < window.innerHeight - elementRect.bottom) {
            return 'bottom';
        }
        return 'top';
    },
};

export default TooltipHelper;
